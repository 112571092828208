import React, { useState } from 'react';
import {
  Table,
  Spin,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Card,
  Checkbox,
  Select,
  Progress,
  Tooltip,
} from 'antd';
import {
  InfoCircleOutlined,
  DownloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { utils, writeFile } from 'xlsx';

import benchmarkApi from '../../api/benchmark';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';

const { Text, Title } = Typography;

function Benchmark() {
  const { t } = useTranslation();
  const [competitors, setCompetitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [classificationProgress, setClassificationProgress] = useState(0);

  const marketplaces = [
    { value: 'Amazon', label: 'Amazon (AMAZON US)' },
    { value: 'Amazon Mexico', label: 'Amazon Mexico' },
    { value: 'Amazon Canada', label: 'Amazon Canada' },
    { value: 'Amazon Brazil', label: 'Amazon Brazil' },
    { value: 'Amazon Vendor Brazil', label: 'Amazon Vendor Brazil' },
    { value: 'Amazon Europe', label: 'Amazon Europe' },
    { value: 'Amazon Spain', label: 'Amazon Spain' },
    { value: 'Amazon UK', label: 'Amazon UK' },
    { value: 'Amazon France', label: 'Amazon France' },
    { value: 'Amazon Belgium', label: 'Amazon Belgium' },
    { value: 'Amazon Netherlands', label: 'Amazon Netherlands' },
    { value: 'Amazon Germany', label: 'Amazon Germany' },
    { value: 'Amazon Italy', label: 'Amazon Italy' },
    { value: 'Amazon Sweden', label: 'Amazon Sweden' },
    { value: 'Amazon Poland', label: 'Amazon Poland' },
    { value: 'Amazon Egypt', label: 'Amazon Egypt' },
    { value: 'Amazon Turkey', label: 'Amazon Turkey' },
    { value: 'Amazon Saudi Arabia', label: 'Amazon Saudi Arabia' },
    {
      value: 'Amazon United Arab Emirates',
      label: 'Amazon United Arab Emirates',
    },
    { value: 'Amazon India', label: 'Amazon India' },
  ];

  const benchmarkProduct = async (
    searchKeywords,
    searchCompetitors,
    useExactMatch,
    exactMatch,
    marketplace
  ) => {
    // Check if the number of competitors is valid
    if (searchCompetitors < 1 || searchCompetitors > 50) {
      openNotification({
        status: false,
        content: t('benchmark.errors.invalidCompetitorCount'),
      });
      return;
    }

    setLoading(true);
    setClassificationProgress(0);
    try {
      let combinedKeywords = searchKeywords;
      if (useExactMatch && exactMatch) {
        combinedKeywords = `${searchKeywords} ${exactMatch}`;
      }
      const params = {
        marketplace,
        keywords: combinedKeywords,
        numberOfCompetitors: searchCompetitors,
      };

      if (useExactMatch) {
        params.exactMatch = [exactMatch];
      }

      const result = await benchmarkApi.getBenchmark(params);
      const initialCompetitors = result.data.competitors;
      setCompetitors(initialCompetitors);

      const totalCompetitors = initialCompetitors.length;

      if (totalCompetitors === 0) {
        setLoading(false); // Add this line to stop loading when there are zero competitors
      } else {
        // Classify each competitor asynchronously
        let classifiedCount = 0;

        initialCompetitors.forEach(async (competitor, index) => {
          try {
            const response = await benchmarkApi.classifyCompetitor(
              competitor,
              marketplace
            );
            const classifiedCompetitor = response.data;

            setCompetitors((prevCompetitors) => {
              const newCompetitors = [...prevCompetitors];
              newCompetitors[index] = classifiedCompetitor;
              return newCompetitors;
            });
          } catch (error) {
            openNotification({
              status: false,
              content: getErrorMessage(error),
            });
          } finally {
            classifiedCount += 1;
            setClassificationProgress(
              Math.round((classifiedCount / totalCompetitors) * 100)
            );
            if (classifiedCount === totalCompetitors) {
              setLoading(false);
            }
          }
        });
      }
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    const {
      searchKeywords,
      searchCompetitors,
      useExactMatch,
      exactMatch,
      marketplace,
    } = values;
    benchmarkProduct(
      searchKeywords,
      searchCompetitors,
      useExactMatch,
      exactMatch,
      marketplace
    );
  };

  const exportToExcel = () => {
    // Process data to include only desired fields
    const dataToExport = competitors.map((comp) => ({
      [t('benchmark.table.link')]: comp.url,
      [t('benchmark.table.productTitle')]: comp.title,
      Asin: comp.externalId,
      [t('benchmark.table.brand')]: comp.brand,
      [t('benchmark.table.price')]: comp.price,
      [t('benchmark.table.sales')]: comp.sales,
      [t('benchmark.table.revenue')]: comp.revenue,
      [t('benchmark.table.fulfillmentType')]: comp.fulfillmentType,
      [t('benchmark.table.productFormatQuantity')]: comp.formatNumber,
      [t('benchmark.table.productFormatUnit')]: comp.formatUnit,
      [t('benchmark.table.unitsPerPack')]: comp.quantity,
      [t('benchmark.table.standardizedFormatUnit')]: comp.standardizedUnit,
      [t('benchmark.table.standardizedFormatQuantity')]:
        comp.standardizedQuantity,
      [t('benchmark.table.totalFormatQuantity')]: comp.totalFormatQuantity,
      [t('benchmark.table.pricePerTotalQuantity')]: comp.pricePerTotalQuantity,
      [t('benchmark.table.rating')]: comp.rating,
      [t('benchmark.table.reviews')]: comp.reviews,
      [t('benchmark.table.category')]: comp.groupName,
    }));

    // Create a worksheet from the processed data
    const ws = utils.json_to_sheet(dataToExport);

    // Create a new workbook and append the worksheet
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, t('benchmark.table.sheetName'));

    // Trigger a download of the Excel file
    writeFile(wb, 'competitors.xlsx');
  };

  const columns = [
    {
      title: t('benchmark.table.productTitle'),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      width: 350,
      render: (text, record) => (
        <div>
          <Text strong>{text}</Text>
          <br />
          <Text type="secondary">ASIN: {record.externalId}</Text>
        </div>
      ),
    },
    {
      title: t('benchmark.table.brand'),
      dataIndex: 'brand',
      key: 'brand',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.price'),
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      width: 100,
      render: (text) => (
        <Text strong style={{ color: 'green' }}>
          {text}
        </Text>
      ),
    },
    {
      title: t('benchmark.table.sales'),
      dataIndex: 'sales',
      key: 'sales',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.revenue'),
      dataIndex: 'revenue',
      key: 'revenue',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.fulfillmentType'),
      dataIndex: 'fulfillmentType',
      key: 'fulfillmentType',
      align: 'center',
      width: 150,
    },
    {
      title: t('benchmark.table.productFormatQuantity'),
      dataIndex: 'formatNumber',
      key: 'formatNumber',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.productFormatUnit'),
      dataIndex: 'formatUnit',
      key: 'formatUnit',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.unitsPerPack'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.standardizedFormatUnit'),
      dataIndex: 'standardizedUnit',
      key: 'standardizedUnit',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.standardizedFormatQuantity'),
      dataIndex: 'standardizedQuantity',
      key: 'standardizedQuantity',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.totalFormatQuantity'),
      dataIndex: 'totalFormatQuantity',
      key: 'totalFormatQuantity',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.pricePerTotalQuantity'),
      dataIndex: 'pricePerTotalQuantity',
      key: 'pricePerTotalQuantity',
      align: 'center',
      width: 100,
      render: (text) => (
        <Text strong style={{ color: 'blue' }}>
          {text}
        </Text>
      ),
    },
    {
      title: t('benchmark.table.rating'),
      dataIndex: 'rating',
      key: 'rating',
      align: 'center',
      width: 100,
      render: (text) => <Text strong>{text ? text.toFixed(1) : 'N/A'}</Text>,
    },
    {
      title: t('benchmark.table.reviews'),
      dataIndex: 'reviews',
      key: 'reviews',
      align: 'center',
      width: 100,
    },
    {
      title: t('benchmark.table.category'),
      dataIndex: 'groupName',
      key: 'groupName',
      align: 'center',
      width: 150,
    },
  ];

  return (
    <Card
      style={{
        padding: '30px',
      }}
    >
      {/* Header Section */}
      <Row>
        <Col span={24}>
          <Title level={3}>{t('benchmark.title')}</Title>
        </Col>
      </Row>

      {/* Form Section */}
      <Form layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          {/* Marketplace Selection */}
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={t('benchmark.form.marketplace')}
              name="marketplace"
              rules={[
                {
                  required: true,
                  message: t('benchmark.form.marketplaceError'),
                },
              ]}
            >
              <Select
                placeholder={t('benchmark.form.marketplacePlaceholder')}
                options={marketplaces}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          {/* Number of Competitors with Tooltip */}
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={
                <span>
                  {t('benchmark.form.maxNumberOfCompetitors')}{' '}
                  <Tooltip
                    title={t('benchmark.form.maxNumberOfCompetitorsTooltip')}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="searchCompetitors"
              rules={[
                {
                  required: true,
                  message: t('benchmark.form.maxNumberOfCompetitorsError'),
                },
              ]}
            >
              <Input
                placeholder={t(
                  'benchmark.form.maxNumberOfCompetitorsPlaceholder'
                )}
                type="number"
              />
            </Form.Item>
          </Col>

          {/* Search Keywords */}
          <Col xs={24} sm={24} md={8}>
            <Form.Item
              label={t('benchmark.form.searchKeywords')}
              name="searchKeywords"
              rules={[
                {
                  required: true,
                  message: t('benchmark.form.searchKeywordsError'),
                },
              ]}
            >
              <Input
                placeholder={t('benchmark.form.searchKeywordsPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Use Exact Match Checkbox and Exact Match Input */}
        <Row gutter={16}>
          {/* Use Exact Match */}
          <Col xs={24} sm={12} md={8}>
            <Form.Item name="useExactMatch" valuePropName="checked">
              <Checkbox>{t('benchmark.form.useExactMatch')}</Checkbox>
            </Form.Item>
          </Col>

          {/* Exact Match Input */}
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.useExactMatch !== currentValues.useExactMatch
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('useExactMatch') ? (
                  <Form.Item
                    label={t('benchmark.form.exactMatch')}
                    name="exactMatch"
                    rules={[
                      {
                        required: true,
                        message: t('benchmark.form.exactMatchError'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t('benchmark.form.exactMatchPlaceholder')}
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>

        {/* Form Actions */}
        <Row gutter={16} justify="start">
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                {t('benchmark.form.searchButton')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {/* Download Button */}
      <Row style={{ marginTop: '16px' }} justify="start">
        <Col>
          <Button
            type="default"
            onClick={exportToExcel}
            disabled={competitors.length === 0}
            icon={<DownloadOutlined />}
          >
            {t('benchmark.downloadExcel')}
          </Button>
        </Col>
      </Row>

      {/* Classification Progress */}
      {classificationProgress > 0 && classificationProgress < 100 && (
        <Progress
          percent={classificationProgress}
          status="active"
          style={{ marginTop: '20px' }}
        />
      )}

      {/* Data Table */}
      <Spin spinning={loading}>
        <Table
          dataSource={competitors}
          columns={columns}
          rowKey="externalId"
          pagination={{ pageSize: 10 }}
          style={{ marginTop: '20px' }}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'even-row' : 'odd-row'
          }
          onRow={(record) => {
            return {
              onClick: () => {
                window.open(record.url, '_blank');
              },
              style: {
                cursor: 'pointer',
              },
            };
          }}
        />
      </Spin>
    </Card>
  );
}

export default Benchmark;
